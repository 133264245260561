/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-04",
    versionChannel: "nightly",
    buildDate: "2024-05-04T00:05:31.022Z",
    commitHash: "d1553a203e679d7bb6e8fc37979970087f344f73",
};
